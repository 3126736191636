import {isNullOrUndefined} from "../../Utilities/commonUtilities";
import {convertToDate, getTodayDate} from "../../Utilities/Types/dateUtilities";
import {courseTypes} from "../Course/courseConstants";
import {
    quarterOptions,
    getReportTypeList
} from "./reportConstants";

export const getReportDetails = (reportType, siteGroupId, allSiteDetails) => {
    const siteReports = getReportTypeList(siteGroupId, allSiteDetails);
    const report = siteReports.find(f => f.value === reportType);
    return report?.reportDetails;
}

export const isTypeOfCourseReport = (reportType, siteGroupId, allSiteDetails) => {
    const siteReports = getReportTypeList(siteGroupId, allSiteDetails);
    return siteReports.find(f => f.value === reportType && f.reportDetails.courseTypes.length > 0);
};

export const isRegularReport = (reportType, siteGroupId, allSiteDetails) => {
    return isTypeOfReport(reportType, siteGroupId, allSiteDetails, courseTypes.RegularCourse);
};

const isTypeOfReport = (reportType, siteGroupId, allSiteDetails, courseType) => {
    const siteReports = getReportTypeList(siteGroupId, allSiteDetails);
    const reports = siteReports.find(f => f.value === reportType && f.reportDetails.courseTypes.some(s => s === courseType));
    return !!reports;
};

export const isHtmlReport = (reportType, siteGroupId, allSiteDetails) => {
    const siteReports = getReportTypeList(siteGroupId, allSiteDetails);
    return siteReports.find(f => f.value === reportType && !isNullOrUndefined(f.reportDetails.htmlComponent));
};

export const getReportComponent = (reportType, siteGroupId, allSiteDetails) => {
    const siteReports = getReportTypeList(siteGroupId, allSiteDetails);
    const report = siteReports.find(f => f.value === reportType);
    return report ? report.reportDetails.htmlComponent : null;
};

export const onlyIncludesCoursesWithAssessments = (reportType, siteGroupId, allSiteDetails) => {
    const siteReports = getReportTypeList(siteGroupId, allSiteDetails);
    const reports = siteReports.find(f => f.value === reportType && f.reportDetails.onlyIncludesCoursesWithAssessments);
    return !!reports;
}

export const usesUserInfoFilter = (reportType, siteGroupId, allSiteDetails) => {
    const siteReports = getReportTypeList(siteGroupId, allSiteDetails);
    const reports = siteReports.find(f => f.value === reportType && f.reportDetails.usesInfoFilter);
    return !!reports;
}

export const getReportDescription = (reportType, siteGroupId, allSiteDetails) => {
    const siteReports = getReportTypeList(siteGroupId, allSiteDetails);
    const report = siteReports.find(f => f.value === reportType);
    return report ? report.reportDetails.reportDescription : "";
}

export const getDateFromQuarterNumber = (quarterNumber, quarterStartOrEndDate, currentDate = null) => {
    const todayDate = !currentDate ? getTodayDate() : currentDate;
    const month = todayDate.getMonth() + 1;
    let year = todayDate.getFullYear();
    let newDate = "";
    switch(quarterNumber.toString()) {
        case "1":
            newDate = quarterStartOrEndDate === quarterOptions.startDate ?  "7/1" : "9/30";
            year = month < 7 ? year - 1 : year;
            break;
        case "2":
            newDate = quarterStartOrEndDate === quarterOptions.startDate ?  "10/1" : "12/31";
            year = month < 10 ? year - 1 : year;
            break;
        case "3":
            newDate = quarterStartOrEndDate === quarterOptions.startDate ?  "1/1" : "3/31";
            break;
        case "4":
            newDate = quarterStartOrEndDate === quarterOptions.startDate ?  "4/1" : "6/30";
            year = month < 4 ? year - 1 : year;
            break;
        case "0":
            newDate = quarterStartOrEndDate === quarterOptions.startDate ?  "7/1" : "6/30";
            year = month < 7 ? year - 1 : year;
            year = quarterStartOrEndDate === quarterOptions.startDate ?  year : year + 1;
            break;
        default:
            return "";
    }

    return `${newDate}/${year}`;
};

export const getQuarterNumberFromDate = (startDate, endDate, currentDate = null) => {
    const start = convertToDate(startDate);
    const end = convertToDate(endDate);

    if(!start || !end)
        return "";

    const startMonth = (start.getMonth() + 1).toString();
    const startDay = (start.getDate()).toString();
    const startYear = start.getFullYear();

    const endMonth = (end.getMonth() + 1).toString();
    const endDay = (end.getDate()).toString();
    const endYear = end.getFullYear();

    let quarterNumber = "";
    if(startMonth === "7" && startDay === "1" && endMonth === "9" && endDay === "30" && startYear === endYear)
        quarterNumber = "1";

    if(startMonth === "10" && startDay === "1" && endMonth === "12" && endDay === "31" && startYear === endYear)
        quarterNumber = "2";

    if(startMonth === "1" && startDay === "1" && endMonth === "3" && endDay === "31" && startYear === endYear)
        quarterNumber = "3";

    if(startMonth === "4" && startDay === "1" && endMonth === "6" && endDay === "30" && startYear === endYear)
        quarterNumber = "4";

    if(startMonth === "7" && startDay === "1" && endMonth === "6" && endDay === "30" && startYear === (endYear - 1))
        quarterNumber = "0";

    if(quarterNumber === "")
        return "";

    const startDateCheck = getDateFromQuarterNumber(quarterNumber, quarterOptions.startDate, currentDate);
    const endDateCheck = getDateFromQuarterNumber(quarterNumber, quarterOptions.endDate, currentDate);

    const startCheck = convertToDate(startDateCheck);
    const endCheck = convertToDate(endDateCheck);

    return startYear === startCheck.getFullYear() && endYear === endCheck.getFullYear() ? quarterNumber : "";
};

export const addOptionalParameters = (reportSearchCriteria) => {
    const {categoryId, districtName, regionId, creditType, userInfo} = reportSearchCriteria;
    let optionalParams = districtName && districtName !== "0" ? `&districtName=${encodeURIComponent(districtName)}` : "";
    optionalParams += regionId > 0 ? `&regionId=${regionId}` : "";
    optionalParams += categoryId > 0 ? `&categoryId=${categoryId}` : "";
    optionalParams = optionalParams + (creditType && parseInt(creditType) > 0 ? `&creditType=${creditType}` : "");
    optionalParams += userInfo ? `&userInfo=${encodeURIComponent(userInfo)}` : "";
    return optionalParams;
}
