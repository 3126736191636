import {ROLES} from "../../../Components/Authorize/roleConstants";
import {isNullOrUndefined} from "../../../Utilities/commonUtilities";
import {getRoleId} from "../../../Utilities/LocalStorage/storageUtilities";
import {searchModel} from "../../../Utilities/Search";
import {associatedTrainings, trainingStatus} from "../courseConstants";

export const courseTrainingSearchResults = (attributes) => ({...attributes});

export const courseTrainingSearchModel = (userId, siteGroupId, nameContains, status, type, trainingStartsAfter, trainingStartsBefore, regionId, myTrainings) => {

    const isTrainerOrSiteAdmin = getRoleId() === ROLES.Trainer || getRoleId() === ROLES.SiteAdmin;

    return Object.assign({}, searchModel(userId.toString(), "courseTitle", false, "courseTitle"), {
        nameContains: nameContains ? nameContains : "",
        status: !isNullOrUndefined(status) ? status : trainingStatus.AcceptingCompletedParticipantsTrainingOffered.id,
        trainingStartsAfter: trainingStartsAfter ? trainingStartsAfter : "",
        trainingStartsBefore: trainingStartsBefore ? trainingStartsBefore : "",
        regionId: regionId ? regionId : "",
        trainerId: isNullOrUndefined(myTrainings) && isTrainerOrSiteAdmin ? userId : "",
        userId: userId ? userId:"",
        type: !isNullOrUndefined(type) ? type : "",
        siteGroupId,
        ClearTrainingSearch: clearTrainingSearch,
        myTrainings: !isNullOrUndefined(myTrainings) ? myTrainings : isTrainerOrSiteAdmin ? associatedTrainings.MyTrainings.id : ""
    });
};

function clearTrainingSearch() {
    this.nameContains = "";
    this.status = "";
    this.type = "";
    this.trainingStartsAfter = "";
    this.trainingStartsBefore = "";
    this.regionId = "";
    this.trainerId = "";
    this.myTrainings = "";
    this.ClearSearch(false);
}